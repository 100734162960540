import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import useStaticImage from '../../../hooks/useStaticImage'

import './styles.scss'

let leaders = [
  {
    name: 'Thiago Montini',
    description: 'Founder & CTO',
    image: 'thiago.webp',
    linkedInUrl: 'https://www.linkedin.com/in/thiagomontini',
  },
  {
    name: 'Talita Montini',
    description: 'Co Founder & CMO',
    image: 'tali.webp',
    linkedInUrl: 'https://www.linkedin.com/in/talitamontini',
  },
  {
    name: 'Leonardo Forato',
    description: 'CSO',
    image: 'leonardo-forato.webp',
    linkedInUrl: 'https://www.linkedin.com/in/antonio-leonardo-forato-0bbbb413/',
  },
  {
    name: 'Nicole Teisein',
    description: 'Tech Manager',
    image: 'nicole-teisen1.webp',
    linkedInUrl: 'https://www.linkedin.com/in/nicole-teisen-camargo',
  },
]

const Leaders = () => {
  const [getImage] = useStaticImage()

  return (
    <section className="leaders">
      <h2 className="leaders__title">Conheça o time de líderes da Digital Republic</h2>
      <div className="leaders__container">
        {leaders.map((leader) => {
          return (
            <div className="leader" key={leader.name}>
              <GatsbyImage
                className="leader__avatar"
                image={getImage(leader.image)}
                loading="lazy"
                width={140}
                quality={95}
                formats={['auto', 'webp', 'avif']}
                alt={leader.name}
              />

              <div>
                <span className="leader__name">{leader.name}</span>
                <br />
                <span className="leader__description">{leader.description}</span>
              </div>

              <a
                className="leader__anchor"
                target="_blank"
                href={leader.linkedInUrl || '#'}
                rel="noreferrer"
              >
                LinkedIn
              </a>
            </div>
          )
        })}
      </div>
    </section>
  )
}

export default Leaders
